import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FormType } from '../../../../models/FormTypes';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import { Option } from '../../../Option';
import ActionPreview from '../../ActionPreview';
import { currentUserAtom } from '../../../../recoil/atoms/Auth';
import ClientFormService from '../../../../services/ClientFormService';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import { getUserResourcePermissions } from '../../../../hooks/permissions/useResourcePermissions';
import { ClientForm } from '../../../../models/ClientForm';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import Loader from '../../../shared/Loader';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { FormRendererType, useFormRendererInfo } from '../../../../contexts/FormRendererContext';

type FormAnswer = ClientForm & { canView: boolean; answeredSubtitle: string; isLoading?: boolean; failed?: false };

const ResourcePicklistPreview: FC<ActionPreviewBaseProps<Option<string, string>[]>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const [forms, setForms] = useState<Record<string, FormAnswer>>({});
  const { t } = useTranslation('common');
  const { type } = useFormRendererInfo();

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const clientFormTemplateService = useMemo(() => currentClient && new ClientTemplateFormService(currentClient.id), [currentClient]);

  useEffect(() => {
    const responses = response || [];

    setForms((prev) => ({ ...prev, ...Object.assign({}, ...responses.map((x) => ({ [x.id]: { isLoading: true } }))) }));
    Promise.allSettled(
      responses.map((resp) =>
        ClientFormService.getForm(resp.id, [404, 403]).catch((err) => {
          throw { ...err, id: resp.id };
        }),
      ),
    ).then(async (allResponses) => {
      for (const res of allResponses) {
        if (res.status === 'fulfilled') {
          let canView = true;
          if (res.value.data.form.type == FormType.Resource && currentUser && currentClient && clientFormTemplateService) {
            const users = await clientFormTemplateService.getUsers(res.value.data.form.id);
            const { canViewRecords } = getUserResourcePermissions(
              users.data.map((el) => {
                return { ...el.user, role: el.role };
              }),
              currentUser,
              currentClient.id,
            );
            canView = canViewRecords;
          }

          setForms((prev) => ({
            ...prev,
            [res.value.data.id]: { ...res.value.data, canView, answeredSubtitle: res.value.data.subtitle, isLoading: false },
          }));
        } else {
          let errorMessage: ParseKeys<'common'> = 'collapsed-form.error.unkown';
          if (res.reason?.meta?.code === 403 || res.reason.status === 403) {
            errorMessage = 'collapsed-form.error.permissions';
          } else if (res.reason?.meta?.code === 404 || res.reason.status === 404) {
            errorMessage = 'collapsed-form.error.notfound';
          }
          setForms((prev) => ({
            ...prev,
            [res.reason?.id]: { answeredSubtitle: t(errorMessage), isLoading: false, failed: true },
          }));
        }
      }
    });
  }, [clientFormTemplateService, currentClient, currentUser, response, t]);

  const answer = useMemo(() => {
    if (!response) {
      return ['-'];
    }

    return Object.values(forms).map((clientForm, i) => {
      if (clientForm.isLoading) {
        return <Loader key="loader" size={8} centered={false} />;
      }
      if (clientForm.failed && !clientForm.isLoading) {
        return <span key="subtitle">{clientForm.answeredSubtitle}</span>;
      }
      if (clientForm?.form.type === FormType.Resource) {
        return clientForm.canView && type === FormRendererType.Platform ? (
          <Link
            onClick={(e) => e.stopPropagation()}
            key={i}
            to={`/clients/${currentClient?.id}/resources/${clientForm?.form.id}/${clientForm.id}`}
            className="underline"
          >
            {clientForm.answeredSubtitle}
          </Link>
        ) : (
          <span key={clientForm.id}>{clientForm.answeredSubtitle}</span>
        );
      }
      return type === FormRendererType.Platform ? (
        <Link onClick={(e) => e.stopPropagation()} key={i} to={`/clients/${currentClient?.id}/forms/${clientForm.id}/preview`} className="underline">
          {clientForm.subtitle}
        </Link>
      ) : (
        <span key={clientForm.id}>{clientForm.subtitle}</span>
      );
    });
  }, [currentClient?.id, forms, response, type]);

  if (answerOnly) {
    const answersWithCommas = answer.flatMap((x) => [x, ', ']);
    answersWithCommas.pop(); // remove last ', '
    return <>{answersWithCommas}</>;
  }

  return <ActionPreview data-cy="resource-picklist-preview" question={title} description={previewDescription} answer={answer} />;
};

export default ResourcePicklistPreview;
